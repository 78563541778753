import PropTypes from 'prop-types';
import React from 'react';
import { AutoFormattedMessage } from '@pcid/string-utils';

const InputSubtext = ({ message, ...rest }) => {
	if (!message) {
		return null;
	}

	let content = null;
	if (React.isValidElement(message)) {
		content = message;
	} else {
		let messageProps = {};
		if (typeof message === 'string') {
			messageProps = { id: message };
		} else {
			messageProps = message;
		}

		content = messageProps.id ? <AutoFormattedMessage {...messageProps} /> : null;
	}

	return <div {...rest}>{content}</div>;
};

InputSubtext.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.node,
	]),
};

export default InputSubtext;
