import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AutoFormattedMessage } from '@pcid/string-utils';

import Breadcrumb from '../../breadcrumb';
import FormMessage from '../form-message';
import { Heading } from '../../layout';

const FormLayout = React.forwardRef(({
	heading,
	description,
	breadcrumb,
	submit,
	className,
	children,
	status,
	showIcon,
	...rest
}, ref) => {
	let formMessage = null;
	if (status) {
		const messageProps = typeof status.message === 'string'
			? { id: status.message }
			: status.message;
		formMessage = (
			<FormMessage
				className="form__message"
				type={status.type}
			>
				<AutoFormattedMessage {...messageProps} />
			</FormMessage>
		);
	}

	const translateObjectItem = (key) => (
		React.isValidElement(key)
			? (key)
			: <AutoFormattedMessage id={key.id} values={key.values} />
	);

	return (
		<form
			ref={ref}
			className={classNames('form', status && `form--${status.type}`, className)}
			noValidate
			{...rest}
		>
			<fieldset className="form__fieldset">
				<legend className="form__legend">
					<Heading className="form__heading" id={heading} showIcon={showIcon} />
					{ breadcrumb && <Breadcrumb className="form__breadcrumb" /> }
					{ description && (Array.isArray(description)
						? description.map((key) => key && (
							<div className="form__description" key={key}>
								{
									typeof key === 'string'
										? <AutoFormattedMessage id={key} />
										: translateObjectItem(key)
								}
							</div>
						))
						: (
							<div className="form__description">
								<AutoFormattedMessage id={description} />
							</div>
						))}
				</legend>
				{formMessage}
				{children}
			</fieldset>
		</form>
	);
});

FormLayout.propTypes = {
	heading: PropTypes.string.isRequired,
	description: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string,
	]),
	breadcrumb: PropTypes.bool,
	showIcon: PropTypes.bool,
	className: PropTypes.string,
	submit: PropTypes.func,
	children: PropTypes.node.isRequired,
	status: PropTypes.object,
};

FormLayout.defaultProps = {
	breadcrumb: false,
};

export default FormLayout;
