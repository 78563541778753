import PropTypes from 'prop-types';
import React from 'react';

/**
 * Render a form checkbox.
 * props.name is used to link a label to the checkbox
 */
const InputCheckbox = (props) => {
	const { innerRef, value, ...elementProps } = props;
	const checked = value || false;
	const id = elementProps.id || elementProps.name;
	return (
		<div className="checkbox-group__input-wrapper">
			<input
				{...elementProps}
				id={id}
				type="checkbox"
				value={checked}
				checked={checked}
				aria-checked={checked}
				className="checkbox-group__input"
				ref={innerRef}
			/>
			<label className="checkbox-group__icon" htmlFor={id}>
				{ checked && (
					<svg
						className="checkbox-group__checkmark"
						focusable="false"
						role="presentation"
					>
						<path d="M 3 9 l 3 3 l 8 -8" />
					</svg>
				) }
			</label>
		</div>
	);
};

InputCheckbox.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
	innerRef: PropTypes.object,
};

export default InputCheckbox;
