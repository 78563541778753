import PropTypes from 'prop-types';
import React from 'react';

import { autoFormatMessage } from '@pcid/string-utils';
import InputIcon from '../input-icon';

/**
 * Required props outlined below.
 *
 * @param props.name {string} Used as the `id` on this compoent, which links a label to this field
 * @param props.placeholder {string:messageId} A message identifier for the placeholder text
 * @returns InputText {ReactComponent}
 */

const InputText = (props) => {
	const {
		id,
		placeholder,
		iconProps,
		innerRef,
		maxLength = 255,
  		spellCheck = false,
		...elementProps
	} = props;

	const placeholderText = placeholder && autoFormatMessage(placeholder);

	return (
		<div className="text-group__input-container">
			<InputIcon
				className="text-group__input-icon"
				{...iconProps}
			/>
			<input
				{...elementProps}
				id={id || elementProps.name}
				placeholder={placeholderText}
				ref={innerRef}
			/>
		</div>
	);
};

InputText.propTypes = {
	id: PropTypes.string,
	placeholder: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	iconProps: PropTypes.object,
	maxLength: PropTypes.number,
	spellCheck: PropTypes.bool,
	innerRef: PropTypes.object,
};


export default InputText;
