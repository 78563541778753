import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Sprite from '@pcid/sprite';

export const getSymbol = ({
	hideIcon,
	showValidatedIcon,
	defaultIcon = null,
	dirty,
	showError,
	errorTheme,
	valid,
	isDropdown,
	asyncValidating,
}) => {
	if (hideIcon) return null;
	if (showError) return errorTheme;
	if (asyncValidating) return 'spinner';
	if (showValidatedIcon && dirty && valid) return 'success';
	if (isDropdown) return 'caret';
	return defaultIcon;
};

const InputIcon = ({ className, ...rest }) => {
	const symbol = getSymbol(rest);
	const spriteProps = { symbol, className: classNames('input-icon', `input-icon--${symbol}`, className) };

	if (symbol === 'spinner') {
		const radius = 69 / (2 * Math.PI); // Circumfrence = 69
		const strokeWidth = 6;
		const center = 14;
		return (
			<svg
				width={`${2 * center}px`}
				height={`${2 * center}px`}
				aria-hidden="true"
				focusable="false"
				role="presentation"
				className={spriteProps.className}
			>
				<circle cx={`${center}px`} cy={`${center}px`} r={`${radius}px`} strokeWidth={`${strokeWidth}px`} />
				<circle cx={`${center}px`} cy={`${center}px`} r={`${radius}px`} strokeWidth={`${strokeWidth}px`} />
			</svg>
		);
	}

	return (
		spriteProps.symbol && <Sprite group="icons" {...spriteProps} />
	);
};

InputIcon.propTypes = {
	showError: PropTypes.bool,
	hideIcon: PropTypes.bool,
	showValidatedIcon: PropTypes.bool,
	defaultIcon: PropTypes.string,
	className: PropTypes.string,
	valid: PropTypes.bool,
	asyncValidating: PropTypes.bool,
	dirty: PropTypes.bool,
	isDropdown: PropTypes.bool,
	errorTheme: PropTypes.string,
};

export default InputIcon;
