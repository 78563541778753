import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

import FormLayout from '../form-layout';

const focusOnField = (field) => {
	if (field && typeof field.focus === 'function') field.focus();
};

// Form manages special focus behavior based on Formik's state (errors, isSubmitting, etc)
class Form extends React.Component {
	form = React.createRef()

	static propTypes = {
		formik: PropTypes.object.isRequired,
	}

	componentDidMount() {
		// focus on first unfilled field initially
		const [firstField] = this.getFields().filter((node) => !node.value);
		focusOnField(firstField);
	}

	componentDidUpdate(prevProps) {
		const { formik: { isSubmitting, errors, status } } = this.props;
		const errorsExist = Object.keys(errors).length || (status && status.type === 'error');

		if (prevProps.formik.isSubmitting && !isSubmitting && errorsExist) {
			// focus on first field if there are no field-level errors, else focus on first invalid field
			const fields = this.getFields();
			const invalidFields = fields.filter((node) => errors[node.name]);
			const firstField = invalidFields[0] || fields[0];
			focusOnField(firstField);
		}
	}

	getFields = () => Array.from(this.form.current)
		.filter((node) => node.nodeName.toLowerCase() === 'input')

	render() {
		const {
			formik: { status, handleSubmit },
			...rest
		} = this.props;

		return (
			<FormLayout
				ref={this.form}
				onSubmit={handleSubmit}
				status={status}
				{...rest}
			/>
		);
	}
}

export default connect(Form);
