import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { AutoFormattedMessage } from '@pcid/string-utils';

/**
 * Error message component.
 */


const InputError = ({ className, message, htmlFor, id, theme = 'error', showError = true }) => {
	if (!showError || !message || JSON.stringify(message) === '{}') {
		return null;
	}
	let messageProps;
	if (typeof message === 'string') {
		messageProps = { id: message };
	} else {
		messageProps = message;
	}
	const classes = classNames('input-error', `input-error--${theme}`, className);

	// <label> uses aria-live instead of role="alert" because this element gets
	// re-rendered with every keystroke while the error message is visible. Some
	// screenreaders (talkback on android) will announce the alert every time.
	return (
		// eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label htmlFor={htmlFor} id={id} className={classes} aria-live="polite" aria-atomic="true">
			<AutoFormattedMessage {...messageProps} />
		</label>
	);
};

InputError.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	showError: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	htmlFor: PropTypes.string.isRequired,
	theme: PropTypes.string,
};


export default InputError;
