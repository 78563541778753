import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Field, connect } from 'formik';
import debounce from 'debounce-promise';
import { autoFormatMessage } from '@pcid/string-utils';
import makeCancelable from '@pcid/utils/make-cancelable';
import noop from '@pcid/utils/noop';

import InputCheckbox from '../input-checkbox';
import InputError from '../input-error';
import InputLabel from '../input-label';
import InputSubtext from '../input-subtext';
import InputText from '../input-text';

const validPropTypes = {
	inputProps: PropTypes.object,
	errorProps: PropTypes.object,
	labelProps: PropTypes.object,
	subtextProps: PropTypes.object,
	iconProps: PropTypes.object,
	className: PropTypes.string,
};


export const Checkbox = ({
	className = '',
	inputProps = {},
	errorProps = {},
	labelProps = {},
}) => {
	const classes = classNames(
		className,
		'checkbox-group',
		errorProps.showError && `checkbox-group--${errorProps.theme}`,
		inputProps.disabled && 'checkbox-group--disabled'
	);

	return (
		<div className={classes}>
			<InputCheckbox {...inputProps} className="checkbox-group__input" />
			<div className="checkbox-group__text">
				<InputLabel {...labelProps} className="checkbox-group__label" />
				<InputError {...errorProps} className="checkbox-group__error" />
			</div>
		</div>
	);
};
Checkbox.propTypes = validPropTypes;

export const Plain = ({
	className = '',
	inputProps = {},
	errorProps = {},
	labelProps = {},
	iconProps = {},
	subtextProps = {},
}) => {
	// Calling this `Text` would be misleading because it's type can be many other things
	const classes = classNames(
		className,
		'text-group',
		'text-group--block',
		errorProps.showError && `text-group--${errorProps.theme}`,
		inputProps.disabled && 'text-group--disabled'
	);

	return (
		<div className={classes}>
			<InputLabel {...labelProps} className="text-group__label" />
			<InputText {...inputProps} className="text-group__input" iconProps={iconProps} />
			<InputError {...errorProps} className="text-group__error error" />
			<InputSubtext {...subtextProps} className="text-group__subtext" />
		</div>
	);
};
Plain.propTypes = validPropTypes;

export const InputGroupLayout = ({
	name,
	required = false,
	disabled = false,
	id,
	className = '',
	label,
	subtext,
	error,
	touched = false,
	alwaysUpdate,
	showOptionalFieldLabel = false,
	showValidatedIcon = false,
	defaultIcon,
	hideIcon = false,
	useNumberKeyboard = false,
	type = 'text',
	ariaLabel,
	submitCount,
	dirty = false,
	asyncValidating = false,
	...rest
}) => {
	const numberProps = useNumberKeyboard ? { inputMode: 'numeric', pattern: '[0-9]*' } : null;

	const inputProps = {
		name,
		id: id || name,
		required,
		type,
		...rest,
	};

	const labelProps = {
		message: label,
		id: `${name}__label`,
		htmlFor: inputProps.id,
		showOptionalFieldLabel: showOptionalFieldLabel && !required,
	};

	const showError = error
		&& !inputProps.disabled
		&& !asyncValidating
		&& ((dirty || touched) || submitCount > 0);

	const wrappedError = typeof error === 'string' ? { id: error } : error;
	const { theme = error && typeof error === 'string' && error.includes('warning') ? 'warning' : 'error', ...message } = wrappedError || {};

	const errorProps = {
		message,
		theme,
		id: `${inputProps.id}__error`,
		htmlFor: inputProps.id,
		showError,
	};

	const subtextProps = {
		message: subtext,
		id: `${inputProps.id}__description`,
	};

	const iconProps = {
		showError,
		errorTheme: theme,
		hideIcon,
		showValidatedIcon,
		defaultIcon,
		asyncValidating,
		dirty,
		valid: !error,
	};

	// Get aria props ready for the input element
	const aria = ariaLabel || labelProps.message;
	const ariaProps = {
		'aria-label': autoFormatMessage(aria),
		'aria-labelledby': classNames(labelProps.id, showError && errorProps.id),
		'aria-describedby': subtextProps.message ? subtextProps.id : null,
		'aria-invalid': showError,
		'aria-disabled': inputProps.disabled?.toString(),
	};

	const childProps = {
		className: classNames('input-group', dirty && 'input-group--dirty', className),
		inputProps: { ...ariaProps, ...numberProps, ...inputProps },
		labelProps,
		subtextProps,
		iconProps,
		errorProps,
	};

	switch (type) {
		case 'checkbox':
			return <Checkbox {...childProps} />;
		default:
			return <Plain {...childProps} />;
	}
};

InputGroupLayout.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	alwaysUpdate: PropTypes.bool,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]).isRequired,
	ariaLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	subtext: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.node,
	]),
	innerRef: PropTypes.object,
	id: PropTypes.string,
	className: PropTypes.string,
	showOptionalFieldLabel: PropTypes.bool,

	// If you want the number keyboard, this prop *will* override the inputMode and pattern props
	useNumberKeyboard: PropTypes.bool,

	// Icon props
	hideIcon: PropTypes.bool,
	defaultIcon: PropTypes.string,
	showValidatedIcon: PropTypes.bool,

	// Form meta props
	asyncValidating: PropTypes.bool,
	error: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	touched: PropTypes.bool,
	dirty: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.bool,
	]),

	// Event handlers
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,

	// This prop comes from Formik
	submitCount: PropTypes.number.isRequired,
};

class InputGroup extends React.Component {
	asyncValidationInProgress = { cancel: noop }

	meta = {}

	static propTypes = {
		name: PropTypes.string.isRequired,
		validate: PropTypes.func,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		validateOnBlur: PropTypes.bool,
		validateOnChange: PropTypes.bool,
		asyncValidate: PropTypes.func,
		onAsyncValidateComplete: PropTypes.func,
		onAsyncValidateError: PropTypes.func,
		formik: PropTypes.object.isRequired,
	};

	static defaultProps = {
		validate: noop,
		onAsyncValidateComplete: noop,
	};

	constructor(props) {
		super(props);
		const { asyncValidate = () => Promise.resolve() } = props;
		this.asyncValidate = debounce(asyncValidate, 300);
	}

	state = {
		asyncValidating: false,
		dirty: false,
	}

	componentWillUnmount() {
		this.asyncValidationInProgress.cancel();
	}

	getValidateBag = () => {
		const { formik } = this.props;
		return {
			getMeta: () => this.meta,
			setMeta: (meta) => { this.meta = meta; },
			formik,
		};
	}

	validate = (value) => {
		this.asyncValidationInProgress.cancel();
		const {
			name,
			validate,
			onAsyncValidateComplete,
			onAsyncValidateError,
			asyncValidate,
			formik: { isValidating, errors },
		} = this.props;
		let result = validate(value, this.getValidateBag());

		if (asyncValidate) {
			// For some reason we don't yet entirely understand yet, isValidating is a perfect
			// indicator that we're doing inline validation, as opposed to pre-submit validation
			// PCID-3557: After upgrading to React 18, concurrent rendering can delay state updates within Formik.
			// This caused issues with 'isValidating' not updating reliably, 
			result = result || this.asyncValidate(value, this.getValidateBag());
		}

		if (result && typeof result.then === 'function') {
			// If we're doing asyncValidation, ...
			this.setState({ asyncValidating: true });
			this.asyncValidationInProgress = makeCancelable(result);

			// Listen for asyncValidationInProgress to finish, and call success/error handlers with the
			// result and the validateBag
			this.asyncValidationInProgress
				.then((res) => onAsyncValidateComplete(res, this.getValidateBag()))
				.catch((err) => (onAsyncValidateError || onAsyncValidateComplete)(err, this.getValidateBag))
				.finally(() => this.setState({ asyncValidating: false }));
			return this.asyncValidationInProgress;
		}

		// If we're just doing synchronous validation, ...
		if (this.state.asyncValidating) {
			this.setState({ asyncValidating: false });
		}
		return result;
	}

	checkForDirtiness = (oldValue, newValue) => {
		if (newValue !== oldValue) {
			this.setState({ dirty: true });
		}
	}

	render() {
		const {
			name,
			validate,
			onChange,
			onBlur,
			validateOnBlur,
			validateOnChange,
			asyncValidate,
			onAsyncValidateComplete,
			onAsyncValidateError,
			formik,
			...rest
		} = this.props;
		const {
			asyncValidating,
			dirty,
		} = this.state;

		return (
			<Field
				name={name}
				validate={this.validate}
				render={({ field, form }) => (
					<InputGroupLayout
						{...field}
						// Formik's Field component has its own onChange and onBlur handlers, so if we want
						// to also support other listeners, we have to explicitly call both the parent's
						// onBlur/onChange and Formik's onBlur/onChange
						// We also have the option here of triggering validation at the field level if the
						// form-level validation triggers have been turned off -- this is the only way to
						// validate fields independently (and it needs to be done in the next frame after
						// Formik has updated the managed value, hence the use of `setTimeout`)
						onChange={(e) => {
							if (!dirty) this.checkForDirtiness(field.value, e.target.value);
							field.onChange(e);
							if (onChange) onChange(e);
							if (!form.validateOnChange && validateOnChange) {
								setTimeout(() => form.validateField(name));
							}
						}}
						onBlur={(e) => {
							if (dirty) field.onBlur(e);
							if (onBlur) onBlur(e);
							if (!form.validateOnBlur && validateOnBlur) {
								setTimeout(() => form.validateField(name));
							}
						}}
						error={form.errors[field.name]}
						touched={form.touched[field.name] || false}
						dirty={dirty}
						asyncValidating={asyncValidating}
						submitCount={form.submitCount}
						{...rest}
					/>
				)}
			/>
		);
	}
}

export default connect(InputGroup);
