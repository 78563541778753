import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Sprite from '@pcid/sprite';
import { autoFormatMessage } from '@pcid/string-utils';

const FormMessage = ({ type, children, className, message, status }) => (
	<div
		className={classNames([
			'form-message',
			`form-message--${type || status}`,
			className,
		])}
		role="alert"
	>
		<Sprite
			className="form-message__icon"
			group="icons"
			symbol={type || status}
		/>
		<div className="form-message__content">
			{children || autoFormatMessage(message)}
		</div>
	</div>
);

FormMessage.propTypes = {
	children: PropTypes.node,
	type: PropTypes.string,
	className: PropTypes.string,
	message: PropTypes.string,
	status: PropTypes.string,
};

export default (FormMessage);
