const makeCancelable = (promise) => {
	let canceled = false;

	const wrappedPromise = new Promise((resolve, reject) => {
		promise
			.then((result) => {
				if (!canceled) resolve(result);
			}).catch((error) => {
				if (!canceled) reject(error);
			});
	});

	wrappedPromise.cancel = () => {
		canceled = true;
	};

	return wrappedPromise;
};

export default makeCancelable;
