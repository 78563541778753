import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { AutoFormattedMessage } from '@pcid/string-utils';
import track from '@pcid/analytics/track';

import Button from '../../button';

const SubmitButton = ({
	formik: { isValidating, isSubmitting },
	messageId = 'form.common.submit',
	analyticsId,
	onClick,
	disabled = false,
	analyticsSubmitPayload,
	...rest
}) => (
	<Button
		type="submit"
		display="block"
		theme="dark"
		disabled={isValidating || disabled}
		submitting={isSubmitting}
		className="submit-button"
		onClick={(e) => {
			if (analyticsId) {
				track.customLink(
					{
						linkName: `${analyticsId}-submit-button`,
						...analyticsSubmitPayload,
					}
				);
			}
			if (onClick) onClick(e);
		}}
		{...rest}
	>
		<AutoFormattedMessage id={messageId} />
	</Button>
);

SubmitButton.propTypes = {
	messageId: PropTypes.string,
	analyticsId: PropTypes.string,
	analyticsSubmitPayload: PropTypes.object,
	formik: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};


export default connect(SubmitButton);
