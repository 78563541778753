import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { AutoFormattedMessage, HiddenColon } from '@pcid/string-utils';

/**
 * Label for a form input element. Can be passed either an internationalized messageId or
 * a FormattedMessage (for cases when there is a more complex label such as the label for
 * accepting the privacy policy, which has a link).
 * Note: Can't return null from a stateless component so return an empty div
 *
 * @param messageId {string|node} either a string which is a messageId, or a FormattedMessage
 * 	component.
 * @param htmlFor {string} the `name` of the target input element.
 * @returns {Component} a rendered React component, even when there is no label.
 */
const InputLabel = ({
	message,
	showOptionalFieldLabel = false,
	className,
	...elementProps
}) => {
	if (!message) {
		return null;
	}

	let messageProps = message;
	if (typeof message === 'string') {
		messageProps = { id: message };
	}
	// The hidden colon below is to ensure there is a pause when being
	// read by the screen reader.  Without the colon, the label sometimes get lost
	// by other text read by the screen reader (e.g. Without the colon, when an
	// error is read in Voiceover, the label is read first and bleeds into the
	// error message without a pause, which is hard to understand).
	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label
			{...elementProps}
			className={classNames('input-label', className)}
		>
			{
				React.isValidElement(messageProps)
					? messageProps
					: <AutoFormattedMessage {...messageProps} />
			}
			{
				showOptionalFieldLabel && (
					<span className="input-label__optional-text">
						<AutoFormattedMessage id="common.optional" />
					</span>
				)
			}
			<HiddenColon />
		</label>
	);
};

InputLabel.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.node,
	]),
	showOptionalFieldLabel: PropTypes.bool,
	className: PropTypes.string,
};


export default InputLabel;
